import { useForm, useWatch } from "react-hook-form"
import { useState, useEffect } from "react"
import styles from "./App.module.css"

export default function App() {
  const [rates, setRates] = useState([])
  const [loading, setLoading] = useState(false)
  const [measurements, setMeasurements] = useState({})

  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  const [typeAndGeometry, setTypeAndGeometry] = useState("")
  const [cutMaterialCost, setCutMaterialCost] = useState(0)
  const [notchesEdgesSealantCost, setNotchesEdgesSealantCost] = useState(0)
  const [totalCost, setTotalCost] = useState(0)
  const [hasCalculated, setHasCalculated] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm()

  const isInfoProvided = () => fullName !== "" && email !== "" && phoneNumber !== ""

  // Fetch the rates from the API
  useEffect(() => {
    fetch("https://www.afjonesinteriors.co.uk/wp-json/wp/v2/hearth_stones")
      .then((response) => response.json())
      .then((data) => {
        const rates = data.reduce((accumulator, current) => {
          const { stone_id } = current.acf
          accumulator[stone_id] = {
            stone_name: current.acf.stone_name,
            stone_id: current.acf.stone_id,
            base: Number(current.acf.base_rate),
            edge: Number(current.acf.edge_rate),
            notch: Number(current.acf.notch_rate),
            sealant: Number(current.acf.sealant_rate),
          }
          return accumulator
        }, {})

        setRates(rates)
      })
      .catch((error) => console.error(error))
  }, [])

  // Only proceed if hasCalculated is true and totalCost is not zero
  useEffect(() => {
    if (hasCalculated && totalCost !== 0) {
      // Create a new object to store all the necessary fields
      const requestData = {
        fullName,
        email,
        phoneNumber,
        typeAndGeometry,
        measurements,
        cutMaterialCost: cutMaterialCost.toFixed(2),
        notchesEdgesSealantCost: notchesEdgesSealantCost.toFixed(2),
        totalCost: totalCost.toFixed(2),
      }

      // Define an async function inside the useEffect
      const fetchData = async () => {
        try {
          setLoading(true)
          const response = await fetch(
            "https://www.afjonesinteriors.co.uk/wp-json/hearth/v1/sendmail",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestData),
            }
          )

          // Check for errors
          if (!response.ok) {
            throw new Error("Network response was not ok")
          }

          // Get the response data
          const responseData = await response.json()

          // Handle the response data here
          console.log(responseData)

          setLoading(false)
        } catch (error) {
          console.error("Error:", error)
          setLoading(false)
        }
      }

      // Call the async function
      fetchData()
    }
  }, [totalCost]) // Only run this effect when totalCost changes

  const RETAIL_MARKUP = 1.15

  const calculateCost = ({
    material,
    x,
    y,
    x1jointed,
    x2jointed,
    y1jointed,
    y2jointed,
    x1single,
    x2single,
    y1single,
    y2single,
  }) => {
    const { base, edge, notch, sealant } = rates[material]

    let cutMaterial
    let notchesEdgesSealant

    if (typeAndGeometry === "rectangle") {
      cutMaterial = base * (((x / 1000) * y) / 1000)
      notchesEdgesSealant = edge * (x / 1000 + (2 * y) / 1000) + sealant
      setMeasurements({ x, y })
    } else if (typeAndGeometry === "t-shape-jointed") {
      cutMaterial =
        base * (((x1jointed / 1000) * y1jointed) / 1000 + ((x2jointed / 1000) * y2jointed) / 1000)
      notchesEdgesSealant = edge * (x1jointed / 1000 + (2 * y1jointed) / 1000) + sealant
      setMeasurements({ x1jointed, x2jointed, y1jointed, y2jointed })
    } else if (typeAndGeometry === "t-shape-single") {
      cutMaterial = base * ((x1single / 1000) * (y1single / 1000 + y2single / 1000))
      notchesEdgesSealant = edge * (x1single / 1000 + (2 * y1single) / 1000) + notch
      setMeasurements({ x1single, x2single, y1single, y2single })
    }

    setCutMaterialCost(cutMaterial)
    setNotchesEdgesSealantCost(notchesEdgesSealant)
    setTotalCost((cutMaterial + notchesEdgesSealant) * RETAIL_MARKUP)
    setHasCalculated(true)
  }

  const handleMaterialChange = (e) => {
    setHasCalculated(false)
    setTypeAndGeometry("")
    setValue("typeAndGeometry", "")
  }

  const handleTypeAndGeometryChange = (e) => {
    setHasCalculated(false)
    setTypeAndGeometry(e.target.value)
    setValue("x", "")
    setValue("y", "")
    setValue("x1jointed", "")
    setValue("x2jointed", "")
    setValue("y1jointed", "")
    setValue("y2jointed", "")
    setValue("x1single", "")
    setValue("x2single", "")
    setValue("y1single", "")
    setValue("y2single", "")
  }

  const onSubmit = async (data) => {
    calculateCost(data)
  }

  return (
    <div className={styles.App}>
      {rates.length === 0 ? null : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>Full Name</label>
            <input
              type="text"
              {...register("fullName", { required: true })}
              aria-invalid={errors.fullName ? "true" : "false"}
              onChange={(e) => setFullName(e.target.value)}
              className={errors.fullName ? styles.required : ""}
            />
          </div>
          <div>
            <label>Email Address</label>
            <input
              type="email"
              {...register("email", { required: true })}
              aria-invalid={errors.email ? "true" : "false"}
              onChange={(e) => setEmail(e.target.value)}
              className={errors.email ? styles.required : ""}
            />
          </div>
          <div>
            <label>Telephone Number</label>
            <input
              type="tel"
              {...register("phoneNumber", { required: true })}
              aria-invalid={errors.phoneNumber ? "true" : "false"}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className={errors.phoneNumber ? styles.required : ""}
            />
          </div>

          {isInfoProvided() && (
            <>
              <div>
                <label>Material</label>
                <select
                  {...register("material", { required: true })}
                  aria-invalid={errors.material ? "true" : "false"}
                  onChange={handleMaterialChange}
                >
                  <option value="">--- Select ---</option>
                  {Object.keys(rates).map((rate) => (
                    <option value={rates[rate].stone_id}>{rates[rate].stone_name}</option>
                  ))}
                </select>
              </div>

              <div>
                <label>Type &amp; Geometry</label>

                <select
                  {...register("typeAndGeometry", { required: true })}
                  aria-invalid={errors.typeAndGeometry ? "true" : "false"}
                  onChange={handleTypeAndGeometryChange}
                >
                  <option value="">--- Select ---</option>
                  <option value="rectangle">Rectangle</option>
                  <option value="t-shape-jointed">T shape (jointed)</option>
                  <option value="t-shape-single">T shape (single piece)</option>
                </select>
              </div>

              {typeAndGeometry === "rectangle" && (
                <div className={styles.row}>
                  <div>
                    <label>x</label>
                    <input
                      type="number"
                      {...register("x", { required: true })}
                      aria-invalid={errors.x ? "true" : "false"}
                      className={errors.x ? styles.required : ""}
                    />
                  </div>
                  <div>
                    <label>y</label>
                    <input
                      type="number"
                      {...register("y", { required: "Y is required" })}
                      aria-invalid={errors.y ? "true" : "false"}
                      className={errors.y ? styles.required : ""}
                    />
                  </div>
                </div>
              )}

              {typeAndGeometry === "t-shape-jointed" && (
                <>
                  <div className={styles.row}>
                    <div>
                      <label>x1 (min 1000)</label>
                      <input
                        type="number"
                        {...register("x1jointed", { required: true })}
                        aria-invalid={errors.x1jointed ? "true" : "false"}
                        className={errors.x1jointed ? styles.required : ""}
                        min={1000}
                      />
                    </div>
                    <div>
                      <label>x2</label>
                      <input
                        type="number"
                        {...register("x2jointed", { required: true })}
                        aria-invalid={errors.x2jointed ? "true" : "false"}
                        className={errors.x2jointed ? styles.required : ""}
                      />
                    </div>
                    <div className={styles.row}>
                      <div>
                        <label>y1 (min 275)</label>
                        <input
                          type="number"
                          {...register("y1jointed", { required: true })}
                          aria-invalid={errors.y1jointed ? "true" : "false"}
                          className={errors.y1jointed ? styles.required : ""}
                          min={275}
                        />
                      </div>
                      <div>
                        <label>y2</label>
                        <input
                          type="number"
                          {...register("y2jointed", { required: true })}
                          aria-invalid={errors.y2jointed ? "true" : "false"}
                          className={errors.y2jointed ? styles.required : ""}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {typeAndGeometry === "t-shape-single" && (
                <>
                  <div className={styles.row}>
                    <div>
                      <label>x1 (min 1000)</label>
                      <input
                        type="number"
                        {...register("x1single", { required: true })}
                        aria-invalid={errors.x1single ? "true" : "false"}
                        className={errors.x1single ? styles.required : ""}
                        min={1000}
                      />
                    </div>
                    <div>
                      <label>(x2)</label>
                      <input
                        type="number"
                        {...register("x2single", { required: true })}
                        aria-invalid={errors.x2single ? "true" : "false"}
                        className={errors.x2single ? styles.required : ""}
                      />
                    </div>
                    <div className={styles.row}>
                      <div>
                        <label>y1 (min 275)</label>
                        <input
                          type="number"
                          {...register("y1single", { required: true })}
                          aria-invalid={errors.y1single ? "true" : "false"}
                          className={errors.y1single ? styles.required : ""}
                          min={275}
                        />
                      </div>
                      <div>
                        <label>y2</label>
                        <input
                          type="number"
                          {...register("y2single", { required: true })}
                          aria-invalid={errors.y2single ? "true" : "false"}
                          className={errors.y2single ? styles.required : ""}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <input type="submit" value="Calculate" />

              {hasCalculated && (
                <div className={styles.costBox}>
                  <p>Cut material: £{cutMaterialCost.toFixed(2)}</p>
                  <p>Notches, edges &amp; sealant: £{notchesEdgesSealantCost.toFixed(2)}</p>
                  <h2>Total: £{totalCost.toFixed(2)}</h2>
                </div>
              )}
            </>
          )}
        </form>
      )}
    </div>
  )
}
